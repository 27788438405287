import { Cart } from '../classes/cart';

const cartManager = new Cart();

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.id === 'cartPage') {
    updateCartPage();
    }
});

function updateCartPage() {
    const cartItemsContainer = document.getElementById('cart-items-container');
    const cartSummaryContainer = document.getElementById('cart-summary-container');

    const cartItems = cartManager.getCartItems();
    cartItemsContainer.innerHTML = '';

    cartItems.forEach(item => {
        const cartItemHTML = `
            <div class="row mx-0 py-4 g-0 border-bottom">
                <div class="col-2 position-relative">
                    <picture class="d-block border">
                        <img class="img-fluid" src="${item.product.image_url}" alt="${item.product.title}">
                    </picture>
                </div>
                <div class="col-9 offset-1">
                    <div>
                        <h6 class="justify-content-between d-flex align-items-start mb-2">
                            ${item.product.title}
                            <i class="ri-close-line ms-3" data-remove-product-id="${item.product_id}"></i>
                        </h6>
                        <span class="d-block text-muted fw-bolder text-uppercase fs-9">Qty: ${item.quantity}</span>
                    </div>
                    <p class="fw-bolder text-end text-muted m-0">${(item.product.currency)} ${(item.product.price * item.quantity).toFixed(2)}</p>
                </div>
            </div>
        `;
        cartItemsContainer.insertAdjacentHTML('beforeend', cartItemHTML);
    });

    const totalItems = cartManager.getTotalItems();
    const totalPrice = cartManager.getTotalPrice();

    const cartSummaryHTML = `
        <div class="pb-4 border-bottom">
            <div class="d-flex flex-column flex-md-row justify-content-md-between mb-4 mb-md-2">
                <div>
                    <p class="m-0 fw-bold fs-5">Grand Total</p>
                </div>
                <p class="m-0 fs-5 fw-bold">R ${totalPrice}</p>
            </div>
        </div>
        <a href="../checkout.html" class="btn btn-dark w-100 text-center" role="button">Proceed to checkout</a>
    `;
    cartSummaryContainer.innerHTML = cartSummaryHTML;

    document.querySelectorAll('[data-remove-product-id]').forEach(button => {
        button.addEventListener('click', (event) => {
            const productId = event.target.dataset.removeProductId;
            cartManager.removeItem(productId);
            updateCartPage();
        });
    });
}
