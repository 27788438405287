const apiEndpoint = process.env.API_ENDPOINT;
import { Cart } from '../classes/cart';

const cartManager = new Cart();

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.id === 'checkoutPaymentPage') {
        updateCheckoutSummary();
        setupPaymentListener();
    }
});

function updateCheckoutSummary() {
    const checkoutSummaryContainer = document.getElementById('checkout-summary-container');
    const cartItems = cartManager.getCartItems();
    
    let cartItemsHTML = '';
    cartItems.forEach(item => {
        cartItemsHTML += `
            <div class="row mx-0 py-4 g-0 border-bottom">
                <div class="col-2 position-relative">
                    ${item.quantity > 1 ? `<span class="checkout-item-qty">${item.quantity}</span>` : ''}
                    <picture class="d-block border">
                        <img class="img-fluid" src="${item.product.image_url}" alt="${item.product.title}">
                    </picture>
                </div>
                <div class="col-9 offset-1">
                    <div>
                        <h6 class="justify-content-between d-flex align-items-start mb-2">
                            ${item.product.title}
                            <i class="ri-close-line ms-3" data-remove-product-id="${item.product.id}"></i>
                        </h6>
                        <span class="d-block text-muted fw-bolder text-uppercase fs-9">Qty: ${item.quantity}</span>
                    </div>
                    <p class="fw-bolder text-end text-muted m-0">${item.product.currency} ${(item.product.price * item.quantity).toFixed(2)}</p>
                </div>
            </div>
        `;
    });

    const totalPrice = cartManager.getTotalPrice();

    const checkoutSummaryHTML = `
        <div class="pb-3">
            ${cartItemsHTML}
        </div>
        <div class="py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center mb-2">
                <p class="m-0 fw-bolder fs-6">Subtotal</p>
                <p class="m-0 fs-6 fw-bolder">R ${totalPrice.toFixed(2)}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center ">
                <p class="m-0 fw-bolder fs-6">Shipping</p>
                <p class="m-0 fs-6 fw-bolder">R 49.99</p>
            </div>
        </div>
        <div class="py-4 border-bottom">
            <div class="d-flex justify-content-between">
                <div>
                    <p class="m-0 fw-bold fs-5">Grand Total</p>
                </div>
                <p class="m-0 fs-5 fw-bold">R ${(totalPrice + 49.99).toFixed(2)}</p>
            </div>
        </div>
    `;
    checkoutSummaryContainer.innerHTML = checkoutSummaryHTML;
    setupRemoveItemListeners(); // Setup remove item listeners after updating the summary
}

function setupRemoveItemListeners() {
    document.querySelectorAll('[data-remove-product-id]').forEach(button => {
        button.addEventListener('click', function (event) {
            const productId = event.target.dataset.removeProductId;
            cartManager.removeItem(productId);
            updateCheckoutSummary(); // Re-populate the checkout summary after removal
        });
    });
}

function setupPaymentListener() {
    const paymentForm = document.getElementById('payment-form');
    if (paymentForm) {
        paymentForm.addEventListener('submit', function (event) {
            event.preventDefault();
        
            const formData = new FormData(event.target);
        
            const paymentInfo = {
                paymentMethod: formData.get('checkoutPaymentMethod'),
                cardDetails: {
                    name: formData.get('cc-name'),
                    number: formData.get('cc-number'),
                    expiration: formData.get('cc-expiration'),
                    cvv: formData.get('cc-cvv')
                }
            };
        
            localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo));
            
            const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
            const shippingInfo = JSON.parse(localStorage.getItem('shippingInfo')) || {};
            const cartItems = cartManager.getCartItems();
            const totalPrice = cartManager.getTotalPrice();
            const orderData = {
                userInfo,
                shippingInfo,
                cartItems,
                totalPrice
            };
            
            fetch(`${apiEndpoint}/add_order.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderData)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    cartManager.clearCart();
                    window.location.href = '../order-success.html';
                } else {
                    alert(data.message || 'An error occurred while processing your order.');
                }
            })
            .catch(error => console.error('Error:', error));
        });
    }
}
