const apiEndpoint = process.env.API_ENDPOINT;

export class Product {
    constructor() {
        this.productsData = [];
        this.productDetails = {};
        this.currentPage = 1;
        this.productsPerPage = 6;
    }

    fetchProducts(categoryId) {
        return fetch(`${apiEndpoint}/fetch_category_products.php?category_id=${categoryId}`)
            .then(response => response.json())
            .then(data => {
                this.productsData = this.sortProductsByFavorites(data);
                return this.productsData;
            })
            .catch(error => console.error('Error fetching products:', error));
    }

    fetchProductById(productId) {
        return fetch(`${apiEndpoint}/fetch_product.php?id=${productId}`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
                this.productDetails = data;
                console.log(this.productDetails);
                return this.productDetails;
            })
            .catch(error => console.error('Error fetching product:', error));
    }

    fetchFilters() {
        return fetch(`${apiEndpoint}/fetch_filters.php`)
            .then(response => response.json())
            .catch(error => console.error('Error fetching filters:', error));
    }
    
    // Sort products by favorite status
    sortProductsByFavorites(productsData) {
        const favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]');
        productsData.sort((a, b) => {
            const aIsFav = favorites.includes(a.id.toString());
            const bIsFav = favorites.includes(b.id.toString());
            return bIsFav - aIsFav; // True values come first
        });
        return productsData;
    }

    // Sort Products according to selected option
    sortByOptions(selectedOption){
        let sortedProducts;
        switch (selectedOption) {
            case '1':
                sortedProducts = this.sortProductsByPriceHigh(this.productsData);
                break;
            case '2':
                sortedProducts = this.sortProductsByPriceLow(this.productsData);
                break;
            case '3':
                sortedProducts = this.sortProductsByName(this.productsData);
                break;
            default:
                return; // No valid sort option selected
        }
        return sortedProducts;
    }

    // Sort products by price high
    sortProductsByPriceHigh(productsData) {
        return productsData.sort((a, b) => b.price - a.price);
    }

    // Sort products by price low
    sortProductsByPriceLow(productsData) {
        return productsData.sort((a, b) => a.price - b.price);
    }

    // Sort products by name
    sortProductsByName(productsData) {
        return productsData.sort((a, b) => a.title.localeCompare(b.title));
    }

    // Sort products by availability
    sortProductsByAvailability(productsData) {
        return productsData.sort((a, b) => (b.isAvailable === a.isAvailable) ? 0 : b.isAvailable ? 1 : -1);
    }

    // Check if product is favorite
    checkIfFavorite(productId) {
        const favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]');
        return favorites.includes(productId.toString());
    }

    applyFilters() {
        const minPrice = parseFloat(document.querySelector('#filter-min-price').value) || 0;
        const maxPrice = parseFloat(document.querySelector('#filter-max-price').value) || Number.MAX_VALUE;
        const selectedBrands = Array.from(document.querySelectorAll('#filter-modal-brands .form-check-input:checked')).map(input => input.value);
        const selectedSizes = Array.from(document.querySelectorAll('#filter-modal-sizes .form-check-input:checked')).map(input => input.value);

        this.filteredProducts = this.productsData.filter(product => {
            const matchesBrand = selectedBrands.length === 0 || selectedBrands.includes(product.brand);
            const matchesSize = selectedSizes.length === 0 || selectedSizes.some(size => product.sizes && product.sizes.includes(size));
            const matchesPrice = product.price >= minPrice && product.price <= maxPrice;
            return matchesBrand && matchesSize && matchesPrice;
        });
    }

}
