const apiEndpoint = process.env.API_ENDPOINT;
(function () {
    document.addEventListener('DOMContentLoaded', () => {
        var navbarExist = document.getElementById('navbar');
        if (navbarExist) {
            const menuToggle = document.querySelector('.nav-link.dropdown-toggle');
            const navbarTogglers = document.querySelectorAll('.navbar-toggler') || [];
            const NAVBAR_ACTIVE_CLASS = 'navbar-active';
            const NAVBAR_BTN_DISABLE_ADD_BODY_CLASS = 'btn-collapse-expand';

            const handleActiveMobile = () => {
                if (document.body.classList.contains(NAVBAR_ACTIVE_CLASS)) {
                    document.body.classList.remove(NAVBAR_ACTIVE_CLASS);
                } else {
                    document.body.classList.add(NAVBAR_ACTIVE_CLASS);
                }
            };

            new Navbar(menuToggle);

            if (navbarTogglers.length > 0) {
                navbarTogglers.forEach((toggler) => {
                    toggler.addEventListener('click', (event) => {
                        if (event.target && event.target.classList.contains(NAVBAR_BTN_DISABLE_ADD_BODY_CLASS)) {
                            return;
                        }
                        handleActiveMobile();
                    });
                });
            }

             // Add event listener for logout button
             const logoutButton = document.getElementById('logoutButton');
             if (logoutButton) {
                 logoutButton.addEventListener('click', () => {
                     localStorage.removeItem('userInfo');
                     localStorage.removeItem('userRole');
                     window.location.href = "/index.html";
                 });
             }
        }
    });
})();

export class Navbar {
    constructor(menuToggle) {
        this.menuToggle = menuToggle;
        this.menuParent = this.menuToggle ? this.menuToggle.closest('.dropdown') : false;
        this.dropdownMenu = this.menuParent ? this.menuParent.querySelector('.dropdown-menu') : false;
        this.showEvents = ['mouseenter'];
        this.hideEvents = ['mouseleave', 'click'];
        this.cssVarBreakPoint = getComputedStyle(document.documentElement).getPropertyValue('--theme-breakpoint-lg') || '992px';
        this.breakpointLG = parseInt(this.cssVarBreakPoint, 10);

        this.initMenu();
        this.loadCategories();
        this.updateNavbar();
    }

    initMenu() {
        const _this = this;

        if (this.menuParent) {
            this.showEvents.forEach((event) => {
                this.menuParent.addEventListener(event, function () {
                    _this.showMenu();
                })
            });
            this.hideEvents.forEach((event) => {
                this.menuParent.addEventListener(event, function () {
                    _this.hideMenu();
                })
            });
        }
    }

    showMenu() {
        if (window.innerWidth < this.breakpointLG) {
            return;
        }

        if (this.dropdownMenu) {
            this.dropdownMenu.classList.add('show');
        }
        if (this.menuToggle) {
            this.menuToggle.classList.add('show');
            this.menuToggle.setAttribute('aria-expanded', 'true');
        }
    }

    hideMenu() {
        if (window.innerWidth < this.breakpointLG) {
            return;
        }

        if (this.dropdownMenu) {
            this.dropdownMenu.classList.remove('show');
        }
        if (this.menuToggle) {
            this.menuToggle.classList.remove('show');
            this.menuToggle.setAttribute('aria-expanded', 'false');
        }
    }

    loadCategories() {
        fetch(`${apiEndpoint}/fetch_categories.php`)
            .then(response => response.json())
            .then(categories => {
                const categoriesList = document.getElementById('navbar-categories');
                categories
                .filter(category => parseInt(category.isMain)) // Filter only main categories
                .forEach(category => {
                    const categoryItem = document.createElement('li');
                    categoryItem.classList.add('nav-item');
                    categoryItem.innerHTML = `<a class="nav-link" href="category.html?id=${category.id}">${category.category_name}</a>`;
                    categoriesList.prepend(categoryItem);
                });
            })
            .catch(error => console.error('Error fetching categories:', error));
    }

    updateNavbar() {
        const userInfoString = localStorage.getItem('userInfo');
        const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
        const userRole = localStorage.getItem('userRole');
        const adminMenuItem = document.getElementById('adminMenuItem');
        const navbarLogin = document.getElementById('navbarLogin');
        const navbarWelcome = document.getElementById('navbarWelcome');
        const userFirstName = document.getElementById('userFirstName');
        const navbarCategories = document.getElementById('navbar-categories');

        if (userInfo) {
            navbarLogin.classList.add('d-none');
            navbarWelcome.classList.remove('d-none');
            userFirstName.textContent = userInfo.firstName;
        }

        if (userRole === 'admin' && adminMenuItem) {
        adminMenuItem.classList.remove('d-none');

        // Move the admin menu item to the end of the navbar-categories list
        if (navbarCategories) {
            navbarCategories.appendChild(adminMenuItem);
        }
    }
    }
}

