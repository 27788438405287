const apiEndpoint = process.env.API_ENDPOINT;
import { Product } from '../classes/product';
import { Cart } from '../classes/cart';
import { updatePagination } from '../components/pagination';
import { initializeSimpleBar } from '../components/simplebar';

// Initialize product and cart managers
const productManager = new Product();
const cartManager = new Cart();

(function () {
    const productOptions = document.querySelectorAll('.product-option select, .product-option input') || [];
    const PARENT_CSS_CLASS = 'product-option';
    const SELECTED_OPTION_CLASS = 'selected-option';

    const handleOptionChange = ({
        event,
        option
    }) => {
        const optionParent = event.target ? event.target.closest(`.${PARENT_CSS_CLASS}`) : false;
        const selectedOptionLabel = optionParent ? optionParent.querySelector(`.${SELECTED_OPTION_CLASS}`) : false;
        const selectedOption = event.target && event.target.value ? event.target.value : false;

        if (optionParent && selectedOptionLabel && selectedOption) {
            selectedOptionLabel.innerText = selectedOption;
        }
    }

    productOptions.forEach((option) => {
        option.addEventListener('change', (event) => {
            handleOptionChange({
                event,
                option
            });
        });
    });
})();

// DOM Loaded event listener
document.addEventListener("DOMContentLoaded", function () {
    if (document.body.id === 'category') {
        init();
    }
});

// Initialize the category page functionalities
function init() {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryId = urlParams.get('id');
    setupCategoryDetails(categoryId);
    setupProducts(categoryId);
    setupLoadMoreButton(categoryId);
    setupSortByOptions(categoryId);
    setupFilters(categoryId);
}

function setupCategoryDetails(categoryId) {
    if (!categoryId) {
        document.getElementById('categoryBannerImg').style.backgroundImage = `url(/assets/images/banners/banner-1.jpg)`;
        document.getElementById('categoryTitle').innerText = "";
        document.getElementById('categoryTitleToolbar').innerText = "Curated just for you";
        document.getElementById('categoryDescription').innerText = "";
        return;
    }

    fetch(`${apiEndpoint}/fetch_category.php?id=${categoryId}`)
        .then(response => response.json())
        .then(data => {
            if (data) {
                document.getElementById('categoryBannerImg').style.backgroundImage = `url(${data.category_image_url})`;
                document.getElementById('categoryTitle').innerText = data.category_name;
                document.getElementById('categoryTitleToolbar').innerText = data.category_name;
                document.getElementById('categoryDescription').innerText = data.category_description;
            }
        })
        .catch(error => console.error('Error fetching category details:', error));
}

// Handles Quick Add or Favorites Button
document.addEventListener('click', function (event) {
    if (event.target.matches('.btn-quick-add')) {
        const productId = parseInt(event.target.dataset.productId, 10);
        const product = productManager.productsData.find(p => p.id === productId);
        if (product) {
            cartManager.addItem(product);
            console.log('Product added to cart');
        } else {
            console.log('Product not added to cart');
        }
    } else if (event.target.matches('.ri-heart-fill')) {
        const productId = event.target.closest('.card').dataset.productId;
        toggleFavorite(productId);
    }
});

// Sets up products by fetching them from the database
function setupProducts(categoryId) {
    productManager.fetchProducts(categoryId).then(() => {
        displayProducts(productManager.productsData, categoryId);
        updatePagination(productManager.productsData.length, productManager.currentPage, productManager.productsPerPage);
    });
}

// Setup the Sort By Options
function setupSortByOptions(categoryId) {
    const sortByButton = document.getElementById('sort-options');
    sortByButton.addEventListener('change', event => {
        event.preventDefault();
        const sortedProducts = productManager.sortByOptions(event.target.value);
        clearProductList();
        displayProducts(sortedProducts, categoryId);
    });
}

// Setup the Load More button
function setupLoadMoreButton(categoryId) {
    const loadMoreButton = document.getElementById('btn-load-more');
    loadMoreButton.addEventListener('click', event => {
        event.preventDefault();
        loadMoreProducts(categoryId);
    });
}

// Setup the Filters
function setupFilters(categoryId) {
    productManager.fetchFilters().then(filters => {
        populateFilters(filters);
    });

    const filterDoneButton = document.getElementById('btn-done-filters');
    filterDoneButton.addEventListener('click', event => {
        event.preventDefault();
        productManager.applyFilters();
        clearProductList();
        displayProducts(productManager.filteredProducts, categoryId);
        updatePagination(productManager.filteredProducts.length, productManager.currentPage, productManager.productsPerPage);
    });

    const filterResetButton = document.getElementById('btn-reset-filters');
    filterResetButton.addEventListener('click', event => {
        event.preventDefault();
        clearProductList();
        resetFilters(categoryId);
    });
}

// Reset filters and update the product display
function resetFilters(categoryId) {
    document.querySelectorAll('.filter-options input[type="checkbox"]').forEach(input => input.checked = false);
    document.querySelectorAll('.filter-price').forEach(slider => {
        if (slider.noUiSlider) {
            slider.noUiSlider.set([0, 1000]);
        }
    });
    document.querySelectorAll('.filter-range, .filter-text-input').forEach(input => {
        input.value = input.defaultValue;
    });
    updateProductDisplay(categoryId);
}

// Display products in the UI
function displayProducts(productsToDisplay, categoryId) {
    const container = document.getElementById('products-container');
    const start = (productManager.currentPage - 1) * productManager.productsPerPage;
    const end = productManager.currentPage * productManager.productsPerPage;
    const slicedData = productManager.sortProductsByFavorites(productsToDisplay.slice(start, end));

    slicedData.forEach(product => {
        container.innerHTML += createProductHTML(product, categoryId);
    });

    updateFavoriteIcons();
}

// Create HTML for a single product
function createProductHTML(product, categoryId) {
    const isFavorite = productManager.checkIfFavorite(product.id);
    const priceHTML = generatePriceHTML(product);
    const badgesHTML = generateBadgesHTML(product);

    return `
    <div class="col">
            <div class="card border border-transparent position-relative overflow-hidden h-100 transparent" data-product-id="${product.id}">
                <div class="card-img position-relative">
                    <div class="card-badges">
                        ${badgesHTML}
                    </div>
                    <span class="icon-container position-absolute top-0 end-0 p-2 z-index-20 ${isFavorite ? 'text-danger' : 'text-muted'}">
                        <i class="ri-heart-fill"></i>
                    </span>
                    <picture class="position-relative overflow-hidden d-block bg-light">
                        <img class="w-100 img-fluid position-relative z-index-10" src="${product.image_url}" alt="Product Image">
                    </picture>
                    <div class="position-absolute start-0 bottom-0 end-0 z-index-20 p-2">
                        <button class="btn btn-quick-add" data-product-id="${product.id}"><i class="ri-add-line me-2"></i> Quick Add</button>
                    </div>
                </div>
                <div class="card-body px-0">
                    <a class="text-decoration-none" href="product.html?id=${product.id}&categoryId=${categoryId}">${product.title}</a>
                    <small class="text-muted d-block">${product.options}</small>
                    ${priceHTML}
                </div>
            </div>
        </div>`;
}

// Generate price HTML based on product availability and sales
function generatePriceHTML(product) {
    const discontinuedOrOutOfStock = product.availability === 'Discontinued' || product.availability === 'Out of Stock';
    return discontinuedOrOutOfStock ?
        `<p class="mt-2 mb-0 small text-muted"><s>${product.currency} ${product.price}</s></p>` :
        (product['sale-price'] ?
            `<p class="mt-2 mb-0 small"><s class="text-muted">${product.currency ? product.currency : 'R'} ${product.price}</s> <span class="text-danger">${product.currency} ${product['sale-price']}</span></p>` :
            `<p class="mt-2 mb-0 small">${product.currency ? product.currency : 'R'} ${product.price}</p>`);
}
// Generate badges HTML for product labels like New, Sale, etc.
function generateBadgesHTML(product) {
    return `
        ${product['sale-price'] ? `<span class="badge badge-card"><span class="f-w-2 f-h-2 bg-danger rounded-circle d-block me-1"></span> Sale</span>` : ''}
        ${product.new ? `<span class="badge badge-card"><span class="f-w-2 f-h-2 bg-success rounded-circle d-block me-1"></span> New In</span>` : ''}
        ${product['sold-out'] ? `<span class="badge badge-card"><span class="f-w-2 f-h-2 bg-secondary rounded-circle d-block me-1"></span> Sold Out</span>` : ''}`;
}

// Clears the HTML to load new items
function clearProductList() {
    productManager.currentPage = 1;
    document.getElementById('products-container').innerHTML = '';
    document.getElementById('btn-load-more').style.display = 'block';
}

// Update the product display based on the current filter settings
function updateProductDisplay(categoryId) {
    productManager.currentPage = 1; // Reset to first page
    displayProducts(productManager.productsData, categoryId);
    updatePagination(productManager.productsData.length, productManager.currentPage, productManager.productsPerPage);
}

// Update favorite icons in the UI
function updateFavoriteIcons() {
    const favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]');
    document.querySelectorAll('.icon-container').forEach(icon => {
        const productId = icon.closest('.card').dataset.productId;
        if (favorites.includes(productId)) {
            icon.classList.add('text-danger');
            icon.classList.remove('text-muted');
        } else {
            icon.classList.remove('text-danger');
            icon.classList.add('text-muted');
        }
    });
}

// Load more products functionality
function loadMoreProducts(categoryId) {
    productManager.currentPage++;  // Increase page count
    displayProducts(productManager.productsData, categoryId);  // Display the next set of products based on current page
    updatePagination(productManager.productsData.length, productManager.currentPage, productManager.productsPerPage);
}

// Toggles the favorites in a sessionStorage
function toggleFavorite(productId) {
    let favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]');
    if (favorites.includes(productId)) {
        favorites = favorites.filter(id => id !== productId);
    } else {
        favorites.push(productId);
    }
    sessionStorage.setItem('favorites', JSON.stringify(favorites));
    updateFavoriteIcons();
}

// Populates the filters with retrieved data
function populateFilters(filters) {
    const brandsContainer = document.querySelector('#filter-modal-brands .filter-options');
    const sizesContainer = document.querySelector('#filter-modal-sizes .filter-options');

    filters.brands.forEach((brand, index) => {
        brandsContainer.innerHTML += `<div class="form-group form-check-custom mb-1">
            <input type="checkbox" class="form-check-input" id="brand-${index}" value="${brand}">
            <label class="form-check-label fw-normal text-body flex-grow-1 d-flex align-items-center" for="brand-${index}">${brand}</label>
        </div>`;
    });

    filters.sizes.forEach((size, index) => {
        sizesContainer.innerHTML += `<div class="form-group d-inline-block me-2 mb-2 form-check-bg form-check-custom">
            <input type="checkbox" class="form-check-bg-input" id="size-${index}" value="${size}">
            <label class="form-check-label fw-normal" for="size-${index}">${size}</label>
        </div>`;
    });

    initializeSimpleBar();
}
