const apiEndpoint = process.env.API_ENDPOINT;
document.addEventListener("DOMContentLoaded", function () {
    if (document.body.id === 'registerPage') {
        setupRegistrationForm(); 
    }
});

function setupRegistrationForm() {
    const registerForm = document.getElementById('register-form');
    
    registerForm.addEventListener('submit', function(event) {
        event.preventDefault();
        
        const formData = new FormData(registerForm);
        
        fetch(`${apiEndpoint}/user_register.php`, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                alert('Registration successful');
                window.location.href = 'login.html';
            } else {
                alert('Registration failed: ' + data.errors.join(', '));
            }
        })
        .catch(error => console.error('Error:', error));
    });
}