import { Product } from '../classes/product';
import { Cart } from '../classes/cart';

const apiEndpoint = process.env.API_ENDPOINT;
const productManager = new Product();
const cartManager = new Cart();

// JavaScript to retrieve the productId from the URL on product.html
document.addEventListener("DOMContentLoaded", function () {
    if (document.body.id === 'product') {
        const urlParams = new URLSearchParams(window.location.search);
        const productId = urlParams.get('id');  // Get 'id' query parameter
        const categoryId = urlParams.get('categoryId'); // Get 'categoryId' query parameter

        if (productId) {
            fetchProductDetails(productId,categoryId);  // Function to fetch product details
        } else {
            console.error("Product ID is missing in the URL.");
            // Handle missing product ID appropriately
        }
    }
});

function fetchProductDetails(productId, categoryId) {
    productManager.fetchProductById(productId).then(productDetails => {
        if (productDetails) {
            updateBreadCrumbs(productDetails, categoryId);
            updateProductInfo(productDetails);
            updateProductGallery(productDetails.image_url, productDetails.images);
            setupAddToCartButton(productDetails);
        } else {
            console.error("Product details could not be fetched.");
            // Handle the case where product details are not available
        }
    }).catch(error => {
        console.error("Error fetching product details:", error);
    });
}


// function updateBreadCrumbs(product, categoryId) {
//     // Assuming 'product' is the object containing all the details needed
//     if (product) {
//         // Update product category in breadcrumb
//         const categoryBreadcrumb = document.getElementById('breadcrumb_category');
//         categoryBreadcrumb.textContent = product.category;
//         categoryBreadcrumb.firstChild.href = `/category.html?category=${product.category}`; // Adjust link as necessary
//         // Update product title in breadcrumb
//         document.getElementById('breadcrumb_product').textContent = product.title;
//     }
// }
function updateBreadCrumbs(product, categoryId) {
    // Assuming 'product' is the object containing all the details needed
    if (product && categoryId) {
        // Fetch the category details if needed
        fetch(`${apiEndpoint}/fetch_category.php?id=${categoryId}`)
            .then(response => response.json())
            .then(category => {
                if (category) {
                    // Update category in breadcrumb
                    const categoryBreadcrumb = document.getElementById('breadcrumb_category');
                    categoryBreadcrumb.textContent = category.category_name ? category.category_name : 'All Products';
                    categoryBreadcrumb.firstChild.href = `/category.html?id=${categoryId}`;
                }
            });

        // Update product title in breadcrumb
        document.getElementById('breadcrumb_product').textContent = product.title;
    }
}



function updateProductInfo(product) {

    document.getElementById('product_title').textContent = product.title;
    document.getElementById('product-brand').textContent = product.brand;
    document.querySelector('.mb-1.fs-2.fw-bold').textContent = product.title;
    document.getElementById('product-price').textContent = `${product.currency ? product.currency : 'R'} ${product.price}`;
    document.getElementById('product-details-content').textContent = product.description;

    // Update sizes
    const sizesContainer = document.querySelector('.mt-4.d-flex.justify-content-start.flex-wrap.align-items-start');
    sizesContainer.innerHTML = ''; // Clear existing sizes
    if (product.sizes) {
        const sizes = product.sizes.split(', ');
        sizes.forEach(size => {
            const sizeOption = document.createElement('div');
            sizeOption.classList.add('form-check', 'form-check-rounded');
            sizeOption.innerHTML = `
                <input class="form-check-input" type="radio" name="size" value="${size}" id="size-${size}">
                <label class="form-check-label me-5" for="size-${size}">${size}</label>
            `;
            sizesContainer.appendChild(sizeOption);
        });
        // Set default size (assuming the first size in the list is the default)
        if (sizes.length > 0) {
            document.getElementById(`size-${sizes[0]}`).checked = true;
        }
    }
}


function updateProductGallery(mainImageUrl, images) {
    const galleryContainer = document.querySelector('.row.g-3');
    galleryContainer.innerHTML = '';

    // Include the main image as the first image
    const mainImageElement = createImageElement(mainImageUrl);
    galleryContainer.appendChild(mainImageElement);

    // Include additional images
    images.forEach(imageUrl => {
        const imageElement = createImageElement(imageUrl);
        galleryContainer.appendChild(imageElement);
    });
}

function createImageElement(imageUrl) {
    const pictureElement = document.createElement('picture');
    const imgElement = document.createElement('img');
    imgElement.classList.add('img-fluid');
    imgElement.setAttribute('data-zoomable', '');
    imgElement.src = imageUrl;
    imgElement.alt = 'Product Image';
    pictureElement.appendChild(imgElement);

    const colElement = document.createElement('div');
    colElement.classList.add('col-12');
    colElement.appendChild(pictureElement);

    return colElement;
}

function setupAddToCartButton(product) {
    const addToCartButton = document.querySelector('.btn.btn-dark.w-100.mt-4.mb-0');
    addToCartButton.addEventListener('click', () => {
        const selectedSize = document.querySelector('input[name="size"]:checked').value;
        const productWithSize = { ...product, selectedSize };
        cartManager.addItem(productWithSize);
        console.log('Product added to cart:', productWithSize);
    });
}
