export function updatePagination(totalItems, currentPage, productsPerPage) {
    const paginationText = document.querySelector('.pagination-text');
    const numberOfItemsShown = Math.min(currentPage * productsPerPage, totalItems);
    paginationText.textContent = `Showing 1 - ${numberOfItemsShown} of ${totalItems} products`;
    updateProgressBar(numberOfItemsShown, totalItems);
    updateLoadMoreButtonVisibility(currentPage, totalItems, productsPerPage);
}

function updateProgressBar(currentCount, totalCount) {
    const progressBar = document.querySelector('.progress-bar');
    const percentage = (currentCount / totalCount) * 100;
    progressBar.style.width = `${percentage}%`;
    progressBar.setAttribute('aria-valuenow', percentage);
}

export function updateLoadMoreButtonVisibility(currentPage, totalItems, productsPerPage) {
    const loadMoreButton = document.getElementById('btn-load-more');
    if (currentPage * productsPerPage >= totalItems) {
        loadMoreButton.style.display = 'none';  // Hide button if no more products to load
    } else {
        loadMoreButton.style.display = 'block';  // Ensure button is visible if more products are available
    }
}
