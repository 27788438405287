const apiEndpoint = process.env.API_ENDPOINT;
let addedImages = [];
let removedImages = [];
let selectedCategories = [];

document.addEventListener('DOMContentLoaded', function () {
    if (document.body.id === 'adminPage') {
        const userRole = localStorage.getItem('userRole'); // Assume userRole is stored in localStorage after login
        if (userRole !== 'admin') {
            window.location.href = '/index.html'; // Redirect to login if not admin
        }

        // Fetch initial data for products, categories, availability, and brands
        fetchProducts();
        fetchCategories();
        fetchAvailability();
        fetchBrands();
        fetchOrders();

        // Add event listeners for form submissions
        document.getElementById('productForm').addEventListener('submit', handleProductFormSubmit);
        document.getElementById('categoryForm').addEventListener('submit', handleCategoryFormSubmit);
        document.getElementById('availabilityForm').addEventListener('submit', handleAvailabilityFormSubmit);
        document.getElementById('brandForm').addEventListener('submit', handleBrandFormSubmit);
        document.getElementById('orderForm').addEventListener('submit', handleOrderFormSubmit);

        // Event delegation for dynamically added content
        document.getElementById('productTableBody').addEventListener('click', function (event) {
            if (event.target && event.target.matches('button.btn-primary')) {
                const productId = event.target.getAttribute('data-id');
                editProduct(productId);
            }
            if (event.target && event.target.matches('button.btn-danger')) {
                const productId = event.target.getAttribute('data-id');
                deleteProduct(productId);
            }
        });

        document.getElementById('categoryTableBody').addEventListener('click', function (event) {
            if (event.target && event.target.matches('button.btn-primary')) {
                const categoryId = event.target.getAttribute('data-id');
                editCategory(categoryId);
            }
            if (event.target && event.target.matches('button.btn-danger')) {
                const categoryId = event.target.getAttribute('data-id');
                deleteCategory(categoryId);
            }
        });

        document.getElementById('availability-list').addEventListener('click', function (event) {
            if (event.target && event.target.matches('button.btn-danger')) {
                const availabilityId = event.target.getAttribute('data-id');
                deleteAvailability(availabilityId);
            }
        });

        document.getElementById('brand-list').addEventListener('click', function (event) {
            if (event.target && event.target.matches('button.btn-danger')) {
                const brandId = event.target.getAttribute('data-id');
                deleteBrand(brandId);
            }
        });

        document.getElementById('ordersTableBody').addEventListener('click', function (event) {
            if (event.target && event.target.matches('button.btn-primary')) {
                const orderId = event.target.getAttribute('data-id');
                editOrder(orderId);
            }
        });

        // Add event listener for category filter change
        document.getElementById('filterCategory').addEventListener('change', filterProductsByCategory);

        // Add event listener for image removal
        document.getElementById('currentImages').addEventListener('click', function (event) {
            if (event.target && event.target.matches('button.btn-remove-image')) {
                const imageUrl = event.target.getAttribute('data-image-url');
                deleteImage(imageUrl, event.target.closest('.position-relative'));
            }
        });

        // Add event listener for adding categories
        document.getElementById('addCategoryButton').addEventListener('click', addCategory);
    }
});

function fetchProducts() {
    fetch(`${apiEndpoint}/fetch_products.php`)
        .then(response => response.json())
        .then(data => {
            window.allProducts = data; // Store all products for filtering
            displayProducts(data);
        });
}

function fetchAvailability() {
    fetch(`${apiEndpoint}/fetch_availability.php`)
        .then(response => response.json())
        .then(data => {
            const availabilitySelect = document.getElementById('productAvailability');
            availabilitySelect.innerHTML = data.map(item => `
                <option value="${item.id}">${item.description}</option>
            `).join('');

            const availabilityList = document.getElementById('availability-list');
            availabilityList.innerHTML = data.map(item => `
                <div class="list-group-item">
                    ${item.description}
                    <button class="btn btn-sm btn-danger float-end" data-id="${item.id}">Delete</button>
                </div>
            `).join('');
        });
}

function fetchBrands() {
    fetch(`${apiEndpoint}/fetch_brands.php`)
        .then(response => response.json())
        .then(data => {
            const brandsSelect = document.getElementById('productBrand');
            brandsSelect.innerHTML = data.map(item => `
                <option value="${item.id}">${item.name}</option>
            `).join('');

            const brandList = document.getElementById('brand-list');
            brandList.innerHTML = data.map(item => `
                <div class="list-group-item">
                    ${item.name}
                    <button class="btn btn-sm btn-danger float-end" data-id="${item.id}">Delete</button>
                </div>
            `).join('');
        });
}

function fetchOrders() {
    fetch(`${apiEndpoint}/fetch_orders.php`)
        .then(response => response.json())
        .then(data => {
            const ordersTableBody = document.getElementById('ordersTableBody');
            ordersTableBody.innerHTML = data.map(order => `
                <tr>
                    <td>${order.id}</td>
                    <td>${formatDate(order.created_at)}</td>
                    <td>${order.first_name}</td>
                    <td>${order.last_name}</td>
                    <td>${order.total}</td>
                    <td>${order.status}</td>
                    <td>
                        <button class="btn btn-sm btn-primary" data-id="${order.id}" data-bs-toggle="modal" data-bs-target="#orderModal">Edit</button>
                    </td>
                </tr>
            `).join('');
        });
}

function fetchCategories() {
    fetch(`${apiEndpoint}/fetch_categories.php`)
        .then(response => response.json())
        .then(data => {
            const categoryList = document.getElementById('categoryTableBody');
            const productCategorySelect = document.getElementById('productCategory');
            const filterCategorySelect = document.getElementById('filterCategory');

            categoryList.innerHTML = data.map(category => `
        <tr>
            <td><img src="${category.category_image_url}" alt="${category.category_name}" class="img-thumbnail" width="100"></td>
            <td>${category.category_name}</td>
            <td>${category.category_description}</td>
            <td>
                <div class="btn-group" role="group" aria-label="Actions">
                    <button class="btn btn-sm btn-primary" data-id="${category.id}" data-bs-toggle="modal" data-bs-target="#categoryModal">Edit</button>
                    <button class="btn btn-sm btn-danger" data-id="${category.id}">Delete</button>
                </div>
            </td>
        </tr>
    `).join('');

            productCategorySelect.innerHTML = data.map(category => `
                <option value="${category.id}">${category.category_name}</option>
            `).join('');

            filterCategorySelect.innerHTML += data.map(category => `
                <option value="${category.id}">${category.category_name}</option>
            `).join('');
        });
}

function handleProductFormSubmit(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append('productId', document.getElementById('productId').value);
    formData.append('productName', document.getElementById('productName').value);
    formData.append('productDescription', document.getElementById('productDescription').value);
    formData.append('productPrice', document.getElementById('productPrice').value);
    formData.append('productSalePrice', document.getElementById('productSalePrice').value == "0" ? null : document.getElementById('productSalePrice').value);

    // Collect selected categories
    formData.append('productCategories', JSON.stringify(selectedCategories));

    formData.append('productMainImage', document.getElementById('productMainImage').value);

    const productImages = document.getElementById('productImages').files;
    for (let i = 0; i < productImages.length; i++) {
        formData.append('productImages[]', productImages[i]);
    }
    formData.append('productAvailability', document.getElementById('productAvailability').value);
    formData.append('productBrand', document.getElementById('productBrand').value);

    // Add the removed images and new images to the form data
    formData.append('removedImages', JSON.stringify(removedImages));

    const productId = document.getElementById('productId').value;
    const apiUrl = productId ? `${apiEndpoint}/update_product.php` : `${apiEndpoint}/add_product.php`;

    fetch(apiUrl, {
        method: 'POST',
        body: formData
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchProducts();
                document.getElementById('productForm').reset();
                addedImages = [];
                removedImages = [];
                selectedCategories = [];
                displaySelectedCategories();
                // Manually trigger the modal close
                const closeModalButton = document.querySelector('#productModal .btn-close');
                closeModalButton.click();
            } else {
                alert('Error saving product');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function handleCategoryFormSubmit(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append('categoryId', document.getElementById('categoryId').value);
    formData.append('categoryName', document.getElementById('categoryName').value);
    formData.append('categoryDescription', document.getElementById('categoryDescription').value);
    formData.append('isMain', document.getElementById('categoryIsMain').checked ? 1 : 0);

    const categoryImage = document.getElementById('categoryImageUrl').files[0];
    if (categoryImage) {
        formData.append('categoryImageUrl', categoryImage);
    }

    const apiUrl = document.getElementById('categoryId').value ? `${apiEndpoint}/update_category.php` : `${apiEndpoint}/add_category.php`;

    fetch(apiUrl, {
        method: 'POST',
        body: formData
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchCategories();
                document.getElementById('categoryForm').reset();
                const closeModalButton = document.querySelector('#categoryModal .btn-close');
                closeModalButton.click();
            } else {
                alert('Error saving category');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function handleAvailabilityFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('availabilityName', document.getElementById('availabilityName').value);
    fetch(`${apiEndpoint}/add_availability.php`, {
        method: 'POST',
        body: formData
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchAvailability();
                document.getElementById('availabilityForm').reset();
                const closeModalButton = document.querySelector('#availabilityModal .btn-close');
                closeModalButton.click();
            } else {
                alert('Error adding availability');
            }
        });
}

function handleBrandFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('brandName', document.getElementById('brandName').value);
    fetch(`${apiEndpoint}/add_brand.php`, {
        method: 'POST',
        body: formData
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchBrands();
                document.getElementById('brandForm').reset();
                const closeModalButton = document.querySelector('#brandModal .btn-close');
                closeModalButton.click();
            } else {
                alert('Error adding brand');
            }
        });
}

function handleOrderFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('orderId', document.getElementById('orderId').value);
    formData.append('orderStatus', document.getElementById('orderStatus').value);
    fetch(`${apiEndpoint}/update_order.php`, {
        method: 'POST',
        body: formData
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchOrders();
                document.getElementById('orderForm').reset();
                const closeModalButton = document.querySelector('#orderModal .btn-close');
                closeModalButton.click();
            } else {
                alert('Error updating order');
            }
        });
}

function editProduct(productId) {
    fetch(`${apiEndpoint}/fetch_product.php?id=${productId}`)
        .then(response => response.json())
        .then(product => {
            document.getElementById('productId').value = product.id;
            document.getElementById('productName').value = product.title;
            document.getElementById('productDescription').value = product.description;
            document.getElementById('productPrice').value = product.price;
            document.getElementById('productSalePrice').value = product.sale_price;

            // Set selected categories
            selectedCategories = product.category_ids.map(category => category);
            displaySelectedCategories();

            document.getElementById('productMainImage').value = product.image_url;
            document.getElementById('productAvailability').value = product.availability_id;
            document.getElementById('productBrand').value = product.brand_id;

            // Display current images
            const currentImages = document.getElementById('currentImages');
            currentImages.innerHTML = product.images.map(image => `
                <div class="me-2 mb-2 position-relative">
                    <img src="${image}" alt="Image" class="img-thumbnail" width="50">
                    <button type="button" class="btn btn-sm btn-danger position-absolute top-0 end-0 btn-remove-image" data-image-url="${image}">X</button>
                </div>
            `).join('');
        });
}

function editCategory(categoryId) {
    fetch(`${apiEndpoint}/fetch_category.php?id=${categoryId}`)
        .then(response => response.json())
        .then(category => {
            document.getElementById('categoryId').value = category.id;
            document.getElementById('categoryName').value = category.category_name;
            document.getElementById('categoryIsMain').checked = category.isMain === 1;
            document.getElementById('categoryDescription').value = category.category_description;
            document.getElementById('currentCategoryImage').innerHTML = category.category_image_url ? `<img src="${category.category_image_url}" alt="${category.category_name}" class="img-thumbnail" width="100">` : '';
        })
        .catch(error => console.error('Error:', error));
}

function editOrder(orderId) {
    fetch(`${apiEndpoint}/fetch_order.php?id=${orderId}`)
        .then(response => response.json())
        .then(order => {
            document.getElementById('orderId').value = order.id;
            document.getElementById('createdAt').value = order.created_at;
            document.getElementById('orderUser').value = `${order.first_name} ${order.last_name}`;
            document.getElementById('orderAddress1').value = `${order.street_address1}`;
            document.getElementById('orderAddress2').value = `${order.street_address2}`;
            document.getElementById('orderCity').value = `${order.city_name}`;
            document.getElementById('orderPostalCode').value = `${order.postal_code}`;
            document.getElementById('orderTotal').value = order.total;
            document.getElementById('orderStatus').value = order.status;

            const orderItemsList = document.getElementById('orderItems');
            orderItemsList.innerHTML = order.items.map(item => `
                <tr>
                    <td>${item.product_id}</td>
                    <td>${item.product_name}</td>
                    <td>${item.quantity}</td>
                    <td>${item.price}</td>
                </tr>
            `).join('');

        })
        .catch(error => console.error('Error:', error));
}

function deleteImage(imageUrl, imageElement) {
    removedImages.push(imageUrl);
    imageElement.remove();
}

function deleteProduct(productId) {
    fetch(`${apiEndpoint}/delete_product.php?id=${productId}`, {
        method: 'DELETE'
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchProducts();
            } else {
                alert('Error deleting product');
            }
        });
}

function deleteCategory(categoryId) {
    fetch(`${apiEndpoint}/delete_category.php?id=${categoryId}`, {
        method: 'DELETE'
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchCategories();
            } else {
                alert('Error deleting category');
            }
        });
}

function deleteAvailability(availabilityId) {
    fetch(`${apiEndpoint}/delete_availability.php?id=${availabilityId}`, {
        method: 'DELETE'
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchAvailability();
            } else {
                alert('Error deleting availability');
            }
        });
}

function deleteBrand(brandId) {
    fetch(`${apiEndpoint}/delete_brand.php?id=${brandId}`, {
        method: 'DELETE'
    }).then(response => response.json())
        .then(data => {
            if (data.success) {
                fetchBrands();
            } else {
                alert('Error deleting brand');
            }
        });
}

function filterProductsByCategory() {
    const selectedCategory = document.getElementById('filterCategory').value;
    const filteredProducts = selectedCategory 
        ? window.allProducts.filter(product => (product.category_ids).includes(parseInt(selectedCategory))) 
        : window.allProducts;
    displayProducts(filteredProducts);
}

function displayProducts(products) {
    const productList = document.getElementById('productTableBody');
    productList.innerHTML = products.map(product => `
        <tr>
            <td><img src="${product.image_url}" alt="${product.title}" class="img-thumbnail" width="100"></td>
            <td>${product.title}</td>
            <td>${product.description}</td>
            <td>${product.price}</td>
            <td>${product.sale_price ? (product.sale_price == "0.00" ? "" : product.sale_price) : ""}</td>
            <td>
                <div class="btn-group" role="group" aria-label="Actions">
                    <button class="btn btn-sm btn-primary" data-id="${product.id}" data-bs-toggle="modal" data-bs-target="#productModal">Edit</button>
                    <button class="btn btn-sm btn-danger" data-id="${product.id}">Delete</button>
                </div>
            </td>
        </tr>
    `).join('');
}

function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function addCategory() {
    const productCategorySelect = document.getElementById('productCategory');
    const selectedCategory = productCategorySelect.value;

    if (selectedCategory && !selectedCategories.includes(selectedCategory)) {
        selectedCategories.push(selectedCategory);
        displaySelectedCategories();
    }
}

function displaySelectedCategories() {
    const selectedCategoriesContainer = document.getElementById('selectedCategoriesContainer');
    selectedCategoriesContainer.innerHTML = selectedCategories.map(categoryId => `
        <div class="badge bg-primary me-2 mb-2">
            ${categoryId}
            <button type="button" class="btn btn-sm btn-danger ms-2 btn-remove-category" data-category-id="${categoryId}">X</button>
        </div>
    `).join('');

    document.querySelectorAll('.btn-remove-category').forEach(button => {
        button.addEventListener('click', function () {
            const categoryId = this.getAttribute('data-category-id');
            selectedCategories = selectedCategories.filter(id => id !== categoryId);
            displaySelectedCategories();
        });
    });
}
