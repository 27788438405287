export class Cart {
    constructor() {
        let cartItemslist = localStorage.getItem('cartItems');
    
        if (cartItemslist === 'undefined' || cartItemslist === null) {
            cartItemslist = [];
            localStorage.setItem('cartItems', JSON.stringify(cartItemslist));
        } else {
            cartItemslist = JSON.parse(cartItemslist);
        }
        this.cartItems = cartItemslist;
    
        this.updateCartDropdown();
        this.initEventListeners();
    }

    initEventListeners() {
        document.addEventListener('DOMContentLoaded', () => {
            const cartDropdownElement = document.getElementById('cart-dropdown');
            if (cartDropdownElement) {
                this.updateCartDropdown();
                cartDropdownElement.addEventListener('click', (event) => {
                    if (event.target.matches('[data-remove-product-id]')) {
                        event.stopPropagation(); // Prevent the dropdown from closing
                        const productId = event.target.dataset.removeProductId;
                        this.removeItem(productId);
                    }
                });
            }
        });
    }

    addItem(product) {
        const existingItem = this.cartItems.find(item => item.product_id === product.id);
        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            this.cartItems.push({ product_id: product.id, quantity: 1, product });
        }
        this.saveCart();
        this.updateCartDropdown();
    }

    removeItem(productId) {
        var itemToRemove = parseInt(productId, 10);
        this.cartItems = this.cartItems.filter(item => item.product_id !== itemToRemove);
        this.saveCart();
        this.updateCartDropdown();
    }

    saveCart() {
        localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
    }

    getCartItems() {
        return this.cartItems;
    }

    clearCart() {
        this.cartItems = [];
        this.saveCart();
        this.updateCartDropdown();
    }

    getTotalItems() {
        return this.cartItems.reduce((total, item) => total + item.quantity, 0);
    }

    getTotalPrice() {
        return parseFloat(this.cartItems.reduce((total, item) => total + (item.product.price * item.quantity), 0).toFixed(2));
    }

    updateCartDropdown() {
        const cartDropdown = document.querySelector('.cart-dropdown');
        if (!cartDropdown) return;

        const cartItemsContainer = cartDropdown.querySelector('.cart-items-container');
        cartItemsContainer.innerHTML = '';

        this.cartItems.forEach(item => {
            const cartItemHTML = `
                <div class="row mx-0 py-4 g-0 border-bottom">
                    <div class="col-2 position-relative">
                        <picture class="d-block border">
                            <img class="img-fluid" src="${item.product.image_url}" alt="${item.product.title}">
                        </picture>
                    </div>
                    <div class="col-9 offset-1">
                        <div>
                            <h6 class="justify-content-between d-flex align-items-start mb-2">
                                ${item.product.title}
                                <i class="ri-close-line ms-3" data-remove-product-id="${item.product_id}"></i>
                            </h6>
                            <span class="d-block text-muted fw-bolder text-uppercase fs-9">Qty: ${item.quantity}</span>
                        </div>
                        <p class="fw-bolder text-end text-muted m-0">R ${(item.product.price * item.quantity).toFixed(2)}</p>
                    </div>
                </div>
            `;
            cartItemsContainer.insertAdjacentHTML('beforeend', cartItemHTML);
        });

        const totalItems = this.getTotalItems();
        const totalPrice = this.getTotalPrice();

        cartDropdown.querySelector('.cart-summary-items').textContent = `Cart Summary (${totalItems} items)`;
        cartDropdown.querySelector('.cart-total-price').textContent = `R ${totalPrice}`;
    }
}
