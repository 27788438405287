const apiEndpoint = process.env.API_ENDPOINT;
document.addEventListener("DOMContentLoaded", function () {
    if (document.body.id === 'loginPage') {
        setupLoginForm();
        populateLoginForm();
    }
});

function setupLoginForm() {
    const loginForm = document.getElementById('login-form');

    loginForm.addEventListener('submit', function (e) {
        e.preventDefault();

        const formData = new FormData(loginForm);

        // Send the login request
        fetch(`${apiEndpoint}/user_login.php`, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Store the user information and cart items in local storage
                const userInfo = {
                    id: data.user.id,
                    firstName: data.user.first_name,
                    lastName: data.user.last_name,
                    email: data.user.email,
                    role: data.role
                };
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                localStorage.setItem('cartItems', JSON.stringify(data.cart_items));

                localStorage.setItem('userRole', data.role); // Assuming 'role' is returned in the response

                // Redirect to a different page on successful login
                window.location.href = 'index.html';
            } else {
                alert(data.message);
            }
        })
        .catch(error => console.error('Error:', error));
    });
}

function populateLoginForm() {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString) {
        const userInfo = JSON.parse(userInfoString);
        document.getElementById('login-email').value = userInfo.email || '';
    }
}
