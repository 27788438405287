import {
    createPopper
} from '@popperjs/core';

(function () {
    const cartWrap = document.querySelector('.dropdown-cart');
    const dropdown = document.querySelector('.cart-dropdown');
    const closeCartBtns = document.querySelectorAll('.btn-close-cart') || [];

    if (cartWrap) {
        const popperInstance = createPopper(cartWrap, dropdown, {
            placement: 'bottom-end',
        });
        function show() {
            dropdown.setAttribute('data-show', '');
            popperInstance.setOptions({
                modifiers: [{
                    name: 'eventListeners',
                    enabled: true
                }],
            });

            popperInstance.update();
        }

        function hide() {
            dropdown.removeAttribute('data-show');

            popperInstance.setOptions({
                modifiers: [{
                    name: 'eventListeners',
                    enabled: false
                }],
            });
        }

        function closeOnClickOutside(event) {
            let target = event.target;

            do {
                if (target === cartWrap) {
                    return;
                }
                target = target.parentNode;
            } while (target);

            hide();
        }

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach(event => {
            cartWrap.addEventListener(event, show);
        });

        hideEvents.forEach(event => {
            cartWrap.addEventListener(event, hide);
        });

        document.addEventListener('click', function (event) {
            closeOnClickOutside(event);
        });

        document.addEventListener('touchstart', function (event) {
            closeOnClickOutside(event);
        });

        closeCartBtns.forEach(function (btn) {
            btn.addEventListener('click', hide);
            btn.addEventListener('touchstart', hide);
        });

    }
})();